<template>
    <div>
        <v-card class="rounded-l elevation-5">
            <v-card-title>
                <v-text-field
                    solo
                    class="border-12 mb-2"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    style="max-width:400px"
                    dense
                    @keyup.enter="getPullData()"
                
                ></v-text-field>
                <notification :snackbar="snackbar"></notification>
                <v-spacer></v-spacer>
                <v-btn
                small
                color="#fff"
                class="py-5 mr-3"
                @click="getPullData()"
                >
                    <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn
                small
                color="#fff"
                class="py-5 mr-3"
                @click="dialogfilter = true"
                >
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
                <v-btn
                small
                color="#005c37"
                class="py-5 mr-3 text-white"
                >
                    <v-icon>mdi-file-excel</v-icon>
                    <download-excel
                        class="text"
                        :fetch           = "exportExcel"
                        :fields="headersColumn"
                        :before-generate = "startDownload"
                        :before-finish   = "finishDownload">
                        Export Excel
                    </download-excel>
                </v-btn>
                <v-btn
                small
                color="success"
                class="py-5"
                @click="[dialog = true]"
                >
                    Create New
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    dense
                    :headers="headers"
                    :items="report_usages"
                    class="elevation-1 mt-3"
                    :items-per-page="20"
                    :loading="$store.state.overlay"
                    height="578"
                    fixed-header
                    :divider="true"
                    :light="true"
                    :item-class="tr_datatable"
                >     
                    <template v-slot:[`item.trans_date`]="{ item }">
                        {{ item.trans_date ? (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : '' }}
                    </template>                      
                    <template v-slot:[`item.realisation`]="{ item }">
                        {{ $store.getters.convertToCurrencyNoFrac(item.realisation) }}
                    </template> 
                    <template v-slot:[`item.verified`]="{ item }">
                        <v-btn text color="success" v-if="item.verified === 1 || item.verified === '1'" small>Verified</v-btn>
                        <v-btn text color="error" v-else small>Not Verified</v-btn>
                    </template>   
                    <template v-slot:[`item.action`]="{ item }">
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                    class="text-center"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense class="pt-0 pb-0" >
                                <v-list-item @click="showDetail(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-printer</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Print Detail</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="showButtonAprv1 && (item.verified === 0 || item.verified === '0' || item.verified === false)" @click="editDetail(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-lead-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="deleteActivity(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="700px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    Report Usage
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialog = false, clear()]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined class="p-2">
                                    <v-card-text class="p-2">
                                        <v-container fluid class="pt-0 mt-0">
                                            <v-row>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Entity ID <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="entity_id"
                                                        :items="entities"
                                                        item-value="entity_id"
                                                        item-text="description"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) => [getBranchOffice(event)]"
                                                    ></v-autocomplete> 
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Office ID <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="office_id"
                                                        :items="offices"
                                                        item-value="office_id"
                                                        item-text="office"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true 
                                                    ></v-autocomplete> 
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Period Budget <strong style="color:red;">*</strong><span></span></h6>
                                                    <v-menu
                                                        ref="modal_store"
                                                        v-model="modal_store"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="auto"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            solo
                                                            dense
                                                            v-model="date"
                                                            label="Period Budget"
                                                            persistent-hint
                                                            append-icon="mdi-calendar"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="date"
                                                            type="month"
                                                            no-title
                                                            :min="min_month"
                                                            :allowed-dates="allowedMonths"
                                                            @input="[getAccountReturBudget(), modal_store = false]"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Account Num <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="ac_num"
                                                        :items="ac_nums"
                                                        item-value="ac_num"
                                                        :item-text="item => item.ac_num +' - '+ item.ac_name"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) => [getActivityAccount(event)]"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Activity Header Num <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="activity"
                                                        :items="activities"
                                                        item-value="activity_no"
                                                        :item-text="item => item.activity_no +' - '+ item.remark"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) =>  getDetailActivity(event)"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Activity Detail <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="activity_detail"
                                                        :items="activity_details"
                                                        item-value="seq_no"
                                                        :item-text="item => item.seq_no +' - '+ item.remark"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        return-object
                                                        @change="(event) =>  [getDetailActivityDetail(event), getNoReff(event)]"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">No Reference</h6>
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        label="No Reference"
                                                        persistent-hint
                                                        v-model="reff_no"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        disabled
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Trans Date <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-menu
                                                        ref="modal_tr_date"
                                                        v-model="modal_tr_date"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="auto"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            solo
                                                            dense
                                                            v-model="tr_date"
                                                            label="Trans Date"
                                                            persistent-hint
                                                            append-icon="mdi-calendar"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="tr_date"
                                                            type="date"
                                                            no-title
                                                            @input="modal_tr_date = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Realization <span class="red--text"><strong>* </strong></span></h6>
                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                        <div class="v-input__control">
                                                            <div class="v-input__slot">
                                                                <div class="v-text-field__slot">
                                                                    <money class="" name="realisation" v-model.lazy="realisation" v-bind="money"></money>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Verified <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-switch
                                                        inset
                                                        v-model="verified"
                                                        hide-details=true
                                                        class="ma-2 pa-1"
                                                    ></v-switch>
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    <h6 class="blue-lcd mb-1">Description <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-textarea 
                                                    v-model="remark" 
                                                    solo
                                                    dense 
                                                    class="ma-0 pa-0 border-12"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialog = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" v-if="dialogShow === false">
                            <v-btn block class="rounded-l text-white" color="success" @click="submit()">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogfilter"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            Filter
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Entity ID </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="entity_id"
                                    :items="entities"
                                    item-value="entity_id"
                                    item-text="description"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getBranchOffice(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Office ID </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="office_id"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getAccount(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6" >
                                <h6 class="blue-lcd mb-1">Period Budget </h6>
                                <v-menu
                                    ref="modal_filter"
                                    v-model="modal_filter"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="period_budget"
                                        label="Period Budget"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="period_budget"
                                        type="month"
                                        no-title
                                        @input="[modal_filter = false]"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">AC Num </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="ac_num"
                                    :items="ac_nums"
                                    item-value="ac_num"
                                    :item-text="item => item.ac_num +' - '+ item.ac_name"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Start Date </h6>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Start Date"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">End Date </h6>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="End Date"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Bidang</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="bidang"
                                    :items="bidangs"
                                    item-value="key_value"
                                    :item-text="item => item.key_value +' - '+ item.str1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="6">
                                <h6 class="blue-lcd mb-1">Komisi</h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="komisi"
                                    :items="komisies"
                                    item-value="key_value"
                                    :item-text="item => item.key_value +' - '+ item.str1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getPullData(), dialogfilter = false]">Filter</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_delete"
            max-width="400px"
            persistent
        >
            <v-card style="overflow: hidden;">
                <v-card-title class="p-1">                        
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="#e83e8c;"
                        @click="dialog_delete = false"
                    >
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <h6 class="text-center">
                                    {{ detail ? detail.description : '' }}
                                </h6>
                                <p class="text-center">
                                    Are you sure to delete this Report Usage ？
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center p-3">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l p-3" @click="[dialog_delete = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deleteReport()">Delete</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogPrint"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            style="border-radius: 0 !important"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                Print Report Usage
                                <v-spacer></v-spacer>
                                <v-btn class="rounded-l text-white" color="success" @click="printTicket()">Print</v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="mt-4">
                        <div style="width: 960px;" id="divNameReturBudget">
                            <table width="960" cellspacing="0" cellpadding="0" class="container" style="width: 960px; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                <tbody>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width: 80%;">
                                                            <div class="d-flex">
                                                                <img src="@/assets/logo_gki.png" height="60px" alt="">
                                                                <p class="mt-4 ml-4">{{ this.entity_id }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <strong>Report Usage</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                <tbody>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Entity
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.entity_id : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Office
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.office : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Account
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.ac_num : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Account Name
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.ac_name : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Period
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.period : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Activity Header
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.header_activity : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Activity Detail
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.detail_activity : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            No Reference
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.reff_no : '' }}
                                                        </td>
                                                    </tr>   
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Trans Date
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.trans_date : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Description
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? this.detail.description : '' }}
                                                        </td>
                                                    </tr>    
                                                    <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Realisation
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            {{ this.detail ? $store.getters.convertToCurrencyNoFrac(this.detail.realisation) : '' }}
                                                        </td>
                                                        <td style="width:15%; padding-left: 5px;">
                                                            Status
                                                        </td>
                                                        <td style="width:5%">
                                                            :
                                                        </td>
                                                        <td style="width:30%">
                                                            <div v-if="this.detail">
                                                                <v-btn text color="success" v-if="this.detail.verified === 1 || this.detail.verified === '1'" small>Verified</v-btn>
                                                                <v-btn text color="error" v-else small>Not Verified</v-btn>
                                                            </div>
                                                            
                                                        </td>
                                                    </tr>                                                
                                                </tbody>
                                            </table>
                                            <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; border: 1px solid #DDD;">
                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                    <td style="width:15%; padding-left: 5px;">
                                                        Terbilang
                                                    </td>
                                                    <td style="width:5%">
                                                        :
                                                    </td>
                                                    <td colspan="4">
                                                        {{terbilang}}
                                                    </td>
                                                </tr>   
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card-actions class="text-center">
                <v-row justify="center">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogPrint = false, clear()]">Close</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-dialog>
        <v-dialog
            v-model="dialogEdit"
            persistent
            max-width="700px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-5">
                                    Edit Report Usage
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialogEdit = false, clear()]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined class="p-2">
                                    <v-card-text class="p-2">
                                        <v-container fluid class="pt-0 mt-0">
                                            <v-row>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Entity ID <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="entity_id"
                                                        :items="entities"
                                                        item-value="entity_id"
                                                        item-text="description"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) => [getBranchOffice(event)]"
                                                        disabled
                                                    ></v-autocomplete> 
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Office ID <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="office_id"
                                                        :items="offices"
                                                        item-value="office_id"
                                                        item-text="office"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true 
                                                        disabled
                                                    ></v-autocomplete> 
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Period Budget <strong style="color:red;">*</strong><span></span></h6>
                                                    <v-menu
                                                        ref="modal_store"
                                                        v-model="modal_store"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="auto"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            solo
                                                            dense
                                                            v-model="date"
                                                            label="Period Budget"
                                                            persistent-hint
                                                            append-icon="mdi-calendar"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            disabled
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="date"
                                                            type="month"
                                                            no-title
                                                            :min="min_month"
                                                            :allowed-dates="allowedMonths"
                                                            @input="[getAccountReturBudget(), modal_store = false]"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Account Num <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="ac_num"
                                                        :items="ac_nums"
                                                        item-value="ac_num"
                                                        :item-text="item => item.ac_num +' - '+ item.ac_name"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) => [getActivityAccount(event)]"
                                                        disabled
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Activity Header Num <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="activity"
                                                        :items="activities"
                                                        item-value="activity_no"
                                                        :item-text="item => item.activity_no +' - '+ item.remark"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        @change="(event) =>  getDetailActivity(event)"
                                                        disabled
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Activity Detail <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-autocomplete
                                                        dense
                                                        solo
                                                        clearable
                                                        v-model="activity_detail"
                                                        :items="activity_details"
                                                        item-value="seq_no"
                                                        :item-text="item => item.seq_no +' - '+ item.remark"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        return-object
                                                        @change="(event) =>  [getDetailActivityDetail(event), getNoReff(event)]"
                                                        disabled
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">No Reference</h6>
                                                    <v-text-field
                                                        solo
                                                        dense
                                                        label="No Reference"
                                                        persistent-hint
                                                        v-model="reff_no"
                                                        class="ma-0 pa-0 border-12"
                                                        hide-details=true
                                                        disabled
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Trans Date <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-menu
                                                        ref="modal_tr_date"
                                                        v-model="modal_tr_date"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="auto"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            solo
                                                            dense
                                                            v-model="tr_date"
                                                            label="Trans Date"
                                                            persistent-hint
                                                            append-icon="mdi-calendar"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="tr_date"
                                                            type="date"
                                                            no-title
                                                            @input="modal_tr_date = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Realization <span class="red--text"><strong>* </strong></span></h6>
                                                    <div class="v-input ma-0 pa-0 v-input--dense border-12 theme--light v-text-field v-text-field--single-line v-text-field--solo
                                                    dense v-text-field--is-booted v-text-field--enclosed">
                                                        <div class="v-input__control">
                                                            <div class="v-input__slot">
                                                                <div class="v-text-field__slot">
                                                                    <money class="" name="realisation" v-model.lazy="realisation" v-bind="money"></money>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="6">
                                                    <h6 class="blue-lcd mb-1">Verified <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-switch
                                                        inset
                                                        v-model="verified"
                                                        hide-details=true
                                                        class="ma-2 pa-1"
                                                    ></v-switch>
                                                </v-col>
                                                <v-col class="col-12" sm="12" md="12">
                                                    <h6 class="blue-lcd mb-1">Description <span class="red--text"><strong>* </strong></span></h6>
                                                    <v-textarea 
                                                    v-model="remark" 
                                                    solo
                                                    dense 
                                                    class="ma-0 pa-0 border-12"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogEdit = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" >
                            <v-btn block class="rounded-l text-white" color="success" @click="submitEdit()">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { appl_constant } from "@/backend-api/appl_constant/index"
import { entity } from "@/backend-api/entity/master"
import { env_conf } from "@/backend-api/env_conf/index"
import { activity_header } from "@/backend-api/ledger/budget/activity_header"
import { report_usage } from "@/backend-api/ledger/budget/report_usage"
import { office } from "@/backend-api/office/index"
import Notification from "@/components/Notification.vue"
import { Money } from 'v-money'
import { CalculateTerbilang } from "@/model/calculate_terbilang"

export default {
    components: {
        Money, Notification
    },
    data() {
        return {
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            dialog: false,
            dialogfilter: false,
            headersColumn: {
                'Entity ID': 'entity_id',
                'Office': 'office',
                'AC Num': 'ac_num',
                'AC Name': 'ac_name',
                'Period Budget': 'period',
                'Activity Header': 'header_activity',
                'Activity Detail': 'detail_activity',
                'No Reference': 'reff_no',
                'Trans Date': 'trans_date',
                'Description': 'description',
                'Realisation': 'realisation',
                'Status': 'verified',
            },
            headers: [
                { text: '', value: 'action' , align:'left', width:'50'},
                {
                    text: 'Entity',
                    align: 'start',
                    value: 'entity_id', 
                    align:'left', width:'90'},
                { text: 'Office', value: 'office' , align:'left', width:'100'},
                { text: 'Account', value: 'ac_num' , align:'left', width:'150'},
                { text: 'Account Name', value: 'ac_name' , align:'left', width:'200'},
                { text: 'Period', value: 'period' , align:'left', width:'100'},
                { text: 'Activity Header', value: 'header_activity' , align:'left', width:'150'},
                { text: 'Activity Detail', value: 'detail_activity' , align:'left', width:'150'},
                { text: 'No Reference', value: 'reff_no' , align:'left', width:'250'},
                { text: 'Trans Date', value: 'trans_date' , align:'left', width:'120'},
                { text: 'Description', value: 'description' , align:'left', width:'250'},
                { text: 'Realisation', value: 'realisation' , align:'left', width:'120'},
                { text: 'Status', value: 'verified' , align:'left', width:'100'},
            ],
            report_usages: [],
            entities: [],
            entity_id: '',
            offices: [],
            office_id: '',
            ac_nums: [],
            bidangs: [],
            bidang: '',
            komisies: [],
            komisi: '',
            ac_num: '',
            period_budget: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            date_from_retur: '',
            modal_retur: false,
            date_to_retur: '',
            modal_to_retur: false,
            modal_filter: false,
            remark: '',
            date: '',
            modal_store: false,
            min_month: '',
            max_month: '',
            mod_month: '',
            activity: '',
            activities: [],
            details: [],
            bank_accs: [],
            debits: [],
            credits: [],
            detail_ac_num: null,
            detail: null,
            dialogShow: false,
            dialog_delete: false,
            dialogPrint: false,
            terbilang: '',
            showButtonAprv1: true,
            dialog_aprv: false,
            stat_aprv: 'aprv',
            acp: '',
            acps:[],
            envVarBidang:'',
            envVarKomisi:'',
            disabled_pdt: false,
            tr_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_tr_date: false,
            realisation: 0,
            detail_activity: null,
            activity_detail: null,
            activity_details: [],
            reff_no: '',
            dialogPrint: false,
            terbilang: 0,
            verified: false,
            dialogEdit: false
        }
    },
    computed:{
        total(){
            var total = 0
            for (let index = 0; index < this.details.length; index++) {
                if (this.details[index].is_del == 0 || this.details[index].is_del == '0') {
                    total = total + parseFloat(this.details[index].retur_amount);
                }
            }
            
            return (total | 0)
        },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)

        await this.getEnvConf()
        await this.getApplConstant()
        await this.getEntity()
        await this.getPullData()

        var current = new Date()
        var start_month = new Date(current.getFullYear(), 0, 2)
        var end = (new Date(current.getFullYear(), 11, 31))
        this.min_month = new Date(start_month).toISOString().substr(0, 7)
        this.max_month = new Date(end).toISOString().substr(0, 7)

        this.$store.dispatch('setOverlay', false)
        
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.entity_id = ''
            this.office_id = ''
            this.ac_num = ''
            this.period_budget = ''
            this.date_from = ''
            this.date_to = ''
            this.activity = ''
            this.detail_ac_num = null
            this.remark = ''
            this.details = []
            this.dialogShow = false
            this.realisation = 0
            this.activity_detail = null
            this.verified = false
            this.reff_no = ''
            this.tr_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        },
        async getEnvConf(){
            var respData = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=APRVUSAGE&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respData.status === 200) {
                if (respData.data.data) {
                    if (respData.data.data == 'Y') {
                        this.showButtonAprv1 = true
                    } else {
                        this.showButtonAprv1 = false
                    }
                } else {
                    this.showButtonAprv1 = false
                }
            } else {
                this.showButtonAprv1 = false
            }
            this.envVarBidang = (this.$store.state.user.bidang) ? this.$store.state.user.bidang : ''
            this.envVarKomisi = (this.$store.state.user.komisi) ? this.$store.state.user.komisi : ''
        },
        async getApplConstant(){
            var respData = await appl_constant.fetchApplConstant("?key_code=BUDGETCYCLE&key_value=MONTH", null, false, false, false)
            if (respData.status === 200) {
                if (respData.data.data.length > 0) {
                    this.mod_month = parseInt(respData.data.data[0].int1)
                }
            }
            var pathBidang = "?key_code=BIDANG"
            if(this.envVarBidang != ''){
                pathBidang = `?key_code=BIDANG&key_value=${this.envVarBidang}`
            }
            
            var respDataBidang = await appl_constant.fetchApplConstant(pathBidang, null, false, false, false)
            if (respDataBidang.status === 200) {
                this.bidangs = respDataBidang.data.data
            }
            var pathKomisi = "?key_code=KOMISI"
            if(this.envVarKomisi != ''){
                pathKomisi = `?key_code=KOMISI&key_value=${this.envVarKomisi}`
            }
            var respDataKomisi = await appl_constant.fetchApplConstant(pathKomisi, null, false, false, false)
            if (respDataKomisi.status === 200) {
                this.komisies = respDataKomisi.data.data
            }
        },
        async getEntity(){
            var respData = await entity.fetchEntity("", null, false, false, false)
            if (respData.status === 200) {
                this.entities = respData.data
            }
        },
        async getBranchOffice(event){
            if (event === null || event === '') {
                this.office_id = ''
            } else{
                var respData = await office.fetchOffice(`?entity_id=${this.entity_id ? this.entity_id : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data
                }
            }
        },
        async getAccountReturBudget(event){
            if (event === null || event === '') {
                this.ac_num = ''
            } else{
                var respData = await activity_header.getAccountRetur(`?entity_id=${this.entity_id ? this.entity_id : ''}&komisi=${this.$store.state.user.komisi ? this.$store.state.user.komisi : ''}&bidang=${this.$store.state.user.bidang ? this.$store.state.user.bidang : ''}&office_id=${this.office_id ? this.office_id : ''}&period=${this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : ''}&flag_three=${this.$store.state.user.flag_three ? this.$store.state.user.flag_three : ''}&gl_flag_id=${this.$store.state.user.id_pdt ? this.$store.state.user.id_pdt : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.ac_nums = respData.data.data

                }
            }
        },
        async getActivityAccount(event){
            if (event === null || event === '') {
                this.activity = ''
            } else{
                var respData = await activity_header.getActivityAccountRetur(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&period=${this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : ''}&ac_num=${this.ac_num ? this.ac_num : ''}&flag_three=${this.$store.state.user.flag_three ? this.$store.state.user.flag_three : ''}&gl_flag_id=${this.$store.state.user.id_pdt ? this.$store.state.user.id_pdt : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.activities = respData.data.data
                }
            }
        },
        async getDetailActivity(event){
            if (event === null || event === '') {
                this.activity = ''
            } else {
                var reqBody = {
                    'office_id': this.office_id,
                    'entity_id': this.entity_id,
                    'ac_num': this.ac_num ? this.ac_num : '',
                    'period': this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : '',
                    'activity_no': this.activity ? this.activity : ''
                }
                const respData = await activity_header.getDetailActivityDetail('', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.activity_details = respData.data.data
                }
            }
        },
        getDetailActivityDetail(event){
            if (event === null || event === '') {
                this.activity_detail = null
            } else {
                console.log(this.activity_detail);
            }
        },
        async getNoReff(event){
            if (event === null || event === '') {
                this.reff_no = ''
            } else {
                this.reff_no = ''
                var reqBody = {
                    'office_id': this.office_id,
                    'entity_id': this.entity_id,
                    'ac_num': this.ac_num ? this.ac_num : '',
                    'period': this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : '',
                    'activity_no': this.activity ? this.activity : '',
                    'seq_no': this.activity_detail ? this.activity_detail.seq_no : null,
                    'activity_item_id': this.activity_detail ? this.activity_detail.activity_item_id : null,
                }
                const respData = await report_usage.getNoReff('', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.reff_no = respData.data.data
                }
            }
        },
        allowedMonths(val) {
            return parseInt(val.split('-')[1], 10) % Number(this.mod_month) === 1
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async exportExcel(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''
            var subBidang = `&bidang=${this.bidang ? this.bidang : ''}`
            var komisi = `&komisi=${this.komisi ? this.komisi : ''}`
            if (this.envVarBidang != ''){
                subBidang = `&bidang=${this.envVarBidang}`
            }
            if (this.envVarKomisi != ''){
                komisi = `&komisi=${this.envVarKomisi}`
            }
            var glFlagId = `&gl_flag_id=${this.$store.state.user.id_pdt ? this.$store.state.user.id_pdt : ''}`

            var respData = await report_usage.index(`?search=${this.search ? this.search : ''}&entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_num=${this.ac_num ? this.ac_num : ''}&period=${this.period_budget ? new Date(this.period_budget).toISOString().substr(0, 7).replace(/-/g,"") : ''}&trans_date_start=${start_date}&trans_date_end=${end_date}${subBidang}${komisi}${glFlagId}`, null, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            }
        },
        async getPullData(){
            this.report_usages = []
            this.$store.dispatch('setOverlay', true)

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''
            var subBidang = `&bidang=${this.bidang ? this.bidang : ''}`
            var komisi = `&komisi=${this.komisi ? this.komisi : ''}`
            let flag_three = `&flag_three=${this.$store.state.user.flag_three ? this.$store.state.user.flag_three : ''}`
            if (this.envVarBidang != ''){
                subBidang = `&bidang=${this.envVarBidang}`
            }
            if (this.envVarKomisi != ''){
                komisi = `&komisi=${this.envVarKomisi}`
            }
            var glFlagId = `&gl_flag_id=${this.$store.state.user.id_pdt ? this.$store.state.user.id_pdt : ''}`
            var respData = await report_usage.index(`?search=${this.search ? this.search : ''}&entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_num=${this.ac_num ? this.ac_num : ''}&period=${this.period_budget ? new Date(this.period_budget).toISOString().substr(0, 7).replace(/-/g,"") : ''}&trans_date_start=${start_date}&trans_date_end=${end_date}${subBidang}${komisi}${glFlagId}${flag_three}`, null, false, false, false)
            if (respData.status === 200) {
                this.report_usages = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else{
                this.$store.dispatch('setOverlay', false)
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'office_id': this.office_id,
                'entity_id': this.entity_id,
                'ac_num': this.ac_num ? this.ac_num : '',
                'period': this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'activity_no': this.activity ? this.activity : '',
                'seq_no': this.activity_detail ? this.activity_detail.seq_no : null,
                'activity_item_id': this.activity_detail ? this.activity_detail.activity_item_id : null,
                'trans_date': this.tr_date,
                'description': this.remark,
                'realisation': this.realisation,
                'verified' : this.verified
            }

            const respData = await report_usage.store('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Add Report Usage Successfully',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = false
                this.clear()
                await this.getPullData()
            } else {
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        deleteActivity(item){
            this.detail = item
            this.dialog_delete = true
        },
        showDetail(item){
            this.detail = item
            var terbilang = new CalculateTerbilang(Number(item.realisation))
            this.terbilang = terbilang.pembilang()
            this.dialogPrint = true
        },
        async deleteReport(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'office_id': this.detail.office_id,
                'entity_id': this.detail.entity_id,
                'ac_num': this.detail.ac_num,
                'period': this.detail.period,
                'activity_no': this.detail.activity_no,
                'seq_no': this.detail.seq_no,
                'activity_item_id': this.detail.activity_item_id,
                'item_no': this.detail.item_no
            }

            const respData = await report_usage.destroy('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Delete Report Usage Successfully',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog_delete = false
                this.clear()
                await this.getPullData()
            } else {
                this.$store.dispatch('setOverlay', false)
                this.dialog_delete = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        printTicket(){
            const elem = document.getElementById("divNameReturBudget")
            var domClone = elem.cloneNode(true);
    
            var $printSectionReturBudget = document.getElementById("printSectionReturBudget");
            
            if (!$printSectionReturBudget) {
                var $printSectionReturBudget = document.createElement("div");
                $printSectionReturBudget.id = "printSectionReturBudget";
                document.body.appendChild($printSectionReturBudget);
            }
            
            $printSectionReturBudget.innerHTML = "";
            $printSectionReturBudget.appendChild(domClone);
            window.print();
        },
        async editDetail(item){
            this.$store.dispatch('setOverlay', true)
            console.log(item);
            this.detail = item
            this.entity_id = item.entity_id
            this.office_id = Number(item.office_id)
            await this.getBranchOffice(item.entity_id)
            this.date = item.period ? item.period.substr(0, 4) + '-' + item.period.substr(4, 6) : ''
            await this.getAccountReturBudget()
            this.ac_num = item.ac_num
            await this.getActivityAccount(item.ac_num)
            this.activity = item.activity_no
            await this.getDetailActivity(item.activity_no)
            if(this.activity_details.length > 0){
                for (let index = 0; index < this.activity_details.length; index++) {
                    const element = this.activity_details[index];
                    if (element.seq_no === item.seq_no) {
                        this.activity_detail = element
                    }
                }
            }
            this.reff_no = item.reff_no
            this.tr_date = (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.realisation = Number(item.realisation)
            this.verified = Number(item.verified)
            this.remark = item.description
            this.$store.dispatch('setOverlay', false)
            this.dialogEdit = true
        },
        async submitEdit(){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'office_id': this.office_id,
                'entity_id': this.entity_id,
                'ac_num': this.ac_num ? this.ac_num : '',
                'period': this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'activity_no': this.activity ? this.activity : '',
                'seq_no': this.activity_detail ? this.activity_detail.seq_no : null,
                'activity_item_id': this.activity_detail ? this.activity_detail.activity_item_id : null,
                'trans_date': this.tr_date,
                'description': this.remark,
                'realisation': this.realisation,
                'verified' : this.verified,
                'item_no': this.detail ? this.detail.item_no : '',
                'reff_no': this.detail ? this.detail.reff_no : '',
            }

            const respData = await report_usage.update('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Update Report Successfully',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialogEdit = false
                this.clear()
                await this.getPullData()
            } else {
                this.$store.dispatch('setOverlay', false)
                this.dialogEdit = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        }
    }
}
</script>
  
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}

fieldset.document {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
.v-money { text-align: right !important; }
.tr_choice{
  background-color: #a5d8ed !important;
}
@media screen {
  #printSectionReturBudget {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionReturBudget, #printSectionReturBudget * {
    visibility:visible;
  }
  #printSectionReturBudget {
    position:absolute;
    left:0;
    top:0;
  }
}

</style>